import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { Routing } from '~/routes/routing';
import { RouterStore } from '~/stores';
import Home from './Home';

type Props = {
  routing: RouterStore;
};

const HomeContainer: FC<Props> = ({ routing }): JSX.Element => {
  const toForm = () => routing.push(Routing.FORM);
  const toHome = () => routing.push(Routing.HOME);

  return <Home toForm={toForm} toHome={toHome} />;
};
export default inject('routing')(observer(HomeContainer));
