import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerIMG } from '~/assets/img';
import {
  Button,
  Description,
  FloatDiv,
  Image,
  Paragraph,
  Section,
} from './styles';

const Banner: FC<any> = ({ toForm, ...rest }) => {
  const { t } = useTranslation('translation');

  return (
    <Section {...rest}>
      <FloatDiv>
        <Paragraph>
          {t('techHealthOne')}
          <br />
          {t('techHealthTwo')}
        </Paragraph>
        <Description> {t('descOne')}</Description>
        <Description> {t('descTwo')}</Description>
        <Button onClick={toForm}>{t('submitBanner')}</Button>
      </FloatDiv>
      <Image src={BannerIMG} />
    </Section>
  );
};

export default Banner;
