import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import pt_BR from './pt_BR';

const getLanguage = (): string => navigator.language.replace('-', '_') || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    pt_BR: { translation: pt_BR },
  },
  lng: getLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
