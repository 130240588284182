/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerDetails } from '~/assets/img';
import {
  Description,
  ImageBanner,
  Paragraph,
  Section,
  SectionText,
  TextWrapper,
  Title,
} from './styles';

type Props = {};

const Details: FC<Props> = () => {
  const { t } = useTranslation('translation');

  return (
    <Section id="sectionTechnology">
      <ImageBanner src={BannerDetails} />
      <SectionText>
        <TextWrapper>
          <Title>{t('techHealthTitle')}</Title>
          <Paragraph>{t('techHealthDescOne')}</Paragraph>
          <Description>{t('techHealthDescTwo')}</Description>
        </TextWrapper>
      </SectionText>
    </Section>
  );
};

export default Details;
