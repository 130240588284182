import React, { FC } from 'react';
import {
  Banner,
  Benefits,
  Details,
  Footer,
  Header,
  Results,
  Solutions,
  Technologies,
} from '~/components';
import { Wrapper } from './styles';

type Props = {
  toForm: () => void;
  toHome: () => void;
};

const Home: FC<Props> = ({ toForm, toHome }): JSX.Element => (
  <Wrapper>
    <Header toHome={toHome} toForm={toForm} />
    <Banner toForm={toForm} />
    <Technologies />
    <Results />
    <Details />
    <Benefits />
    <Solutions toForm={toForm} />
    <Footer />
  </Wrapper>
);

export default Home;
