import React, { FC, useEffect } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Banner,
  Benefits,
  Details,
  Footer,
  Header,
  Results,
  Solutions,
  Technologies,
} from '~/components';
import {
  ButtonSubmit,
  Field,
  Line,
  SectionForm,
  TextArea,
  Wrapper,
} from './styles';

type Props = {
  toHome: () => void;
  toForm: () => void;
};

const Form: FC<Props> = ({ toHome, toForm }): JSX.Element => {
  const {
    values,
    errors,
    touched,
    submitForm,
    handleChange,
  }: FormikProps<FormType.Values> = useFormikContext();

  const { t } = useTranslation('translation');

  return (
    <Wrapper>
      <Header toHome={toHome} toForm={toForm} />

      <SectionForm>
        <Line>
          <Field
            placeholder={t('name')}
            error={!!errors.name}
            name="name"
            value={values.name}
            onChange={handleChange('name')}
          />
          <Field
            placeholder={t('lastName')}
            name="lastName"
            value={values.lastName}
            onChange={handleChange('lastName')}
            error={!!errors.lastName}
          />
        </Line>

        <Line>
          <Field
            error={!!errors.phone}
            placeholder={t('phone')}
            onChange={handleChange('phone')}
            name="phone"
            value={values.phone}
          />
          <Field
            error={!!errors.country}
            placeholder={t('country')}
            onChange={handleChange('country')}
            name="country"
            value={values.country}
          />
        </Line>

        <Line>
          <Field
            error={!!errors.email}
            placeholder={t('email')}
            onChange={handleChange('email')}
            name="email"
            value={values.email}
            type="email"
          />
          <Field
            error={!!errors.company}
            placeholder={t('company')}
            onChange={handleChange('company')}
            name="company"
            value={values.company}
          />
        </Line>

        <TextArea
          placeholder={t('message')}
          onChange={handleChange('message')}
          name="message"
          value={values.message}
        />
        <ButtonSubmit onClick={submitForm}>{t('submit')}</ButtonSubmit>
      </SectionForm>
      <Footer showNews={false} />
    </Wrapper>
  );
};

export default Form;
