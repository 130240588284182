import React, { FC } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Routing } from '~/routes/routing';
import { RouterStore } from '~/stores';
import { alert } from '~/utils';
import Form from './Form';

type Props = {
  routing: RouterStore;
};

const validateForm = yup.object({
  nickname: yup.string(),
  company: yup.string(),
  country: yup.string(),
  email: yup.string().required(),
  lastName: yup.string().required(),
  message: yup.string().required(),
  name: yup.string().required(),
  phone: yup.string(),
});

const FormContainer: FC<Props> = ({ routing }): JSX.Element => {
  const { t } = useTranslation('translation');

  const toHome = () => routing.push(Routing.HOME);
  const toForm = () => routing.push(Routing.FORM);

  const fields: any = {
    company: 603190152,
    country: 650582542,
    email: 1720637715,
    lastName: 1673839551,
    message: 1975854996,
    phone: 1372074621,
    name: 1415483333,
  };

  const onSubmit = async (values: any): Promise<any> => {
    alert({
      message: t('sendWithSuccess'),
      type: 'info',
    });

    const formData = new FormData();
    const keys = Object.keys(fields);

    keys.map((key: any) => {
      formData.append(`entry.${fields[key]}`, values[key]);
    });

    await axios({
      url: `https://docs.google.com/forms/d/e/1FAIpQLSesGCPxDjXAyDdR6fRY-ouJEpghJ2kNZO4Vj0HMFH5TsU9-oA/formResponse`,
      method: 'post',
      data: formData,
      responseType: 'json',
    });
  };

  return (
    <Formik
      validationSchema={validateForm}
      onSubmit={onSubmit}
      initialValues={{
        company: '',
        country: '',
        email: '',
        lastName: '',
        message: '',
        '1415483333': '',
        phone: '',
      }}
    >
      <Form toHome={toHome} toForm={toForm} />
    </Formik>
  );
};
export default inject('routing')(observer(FormContainer));
