/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerSolutions } from '~/assets/img';
import {
  Button,
  Description,
  FooterDescription,
  FooterText,
  ImageBanner,
  Paragraph,
  Section,
  SectionText,
  TextWrapper,
  Title,
} from './styles';

type Props = {
  toForm: () => void;
};

const Solutions: FC<Props> = ({ toForm }) => {
  const { t } = useTranslation('translation');

  return (
    <Section id="sectionSolutions">
      <ImageBanner src={BannerSolutions} />
      <SectionText>
        <TextWrapper>
          <Title>{t('solutionsTitle')}</Title>
          <Paragraph>{t('solutionsDescOne')}</Paragraph>

          <Description>{t('solutionsDescTwo')}</Description>
        </TextWrapper>
      </SectionText>
      <FooterDescription>
        <FooterText>{t('solutionsFinish')}</FooterText>

        <Button onClick={toForm}>{t('contactUs')}</Button>
      </FooterDescription>
    </Section>
  );
};

export default Solutions;
