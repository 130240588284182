import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { pxToRem } from '~/utils';

export const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
`;

export const Section = styled.div`
  flex-direction: row;
  background-color: #ededed;
  align-items: center;
  min-height: ${pxToRem(270)};

  @media ${bk.inMobile} {
    flex-direction: column;
    padding: ${pxToRem(30)} 0px;
  }
`;

export const SectionText = styled.div`
  width: 33%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  min-height: ${pxToRem(200)};
  justify-content: space-evenly;
  margin-bottom: ${pxToRem(40)};

  @media ${bk.inMobile} {
    margin-bottom: ${pxToRem(20)};
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
  margin: 0 auto;
  width: 70%;
  color: #040404;
  font-weight: 300;

  @media ${bk.inMobile} {
    width: 90%;
  }
`;

export const Footer = styled.div`
  background-color: #000;
  width: 100%;
`;

export const Description = styled.h1`
  text-align: center;
  margin: 0 auto;
  width: 70%;
  padding: ${pxToRem(40)} ${pxToRem(30)};
  color: #fff;
  font-weight: 400;
  font-size: 2rem;

  @media ${bk.inMobile} {
    font-size: 1rem;
    width: 100%;
  }
`;

export const IconIMG = styled.img`
  width: ${pxToRem(50)};
`;
