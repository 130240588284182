import styled from 'styled-components';
import bk from '~/theme/breakpoints';
import { ifStyle, pxToRem } from '~/utils';

type PropsField = {
  error?: boolean;
};

// Ifs
const hasError = ifStyle('error');

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
`;

export const SectionForm = styled.div`
  padding: ${pxToRem(30)} 0px;
  margin: 0 auto;
  width: 80%;
  flex-direction: column;
  padding-top: ${pxToRem(150)};

  @media ${bk.inMobile} {
    width: 90%;
  }
`;

export const Line = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  @media ${bk.inMobile} {
    flex-direction: column;
  }
`;

export const Field = styled.input<PropsField>`
  width: 50%;
  font-size: 17px;
  margin: ${pxToRem(5)};
  padding: 5px;
  background-color: #ddd;
  border: 0;
  height: ${pxToRem(50)};
  border-radius: ${pxToRem(4)};
  border-bottom: 2px solid #444;

  border: ${hasError('1.5px solid red', '0px solid red')};

  @media ${bk.inMobile} {
    width: 100%;
    margin: ${pxToRem(5)} 0px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  font-size: 17px;
  margin: ${pxToRem(5)} 0px;
  padding: 5px;
  background-color: #ddd;
  border: 0;
  height: ${pxToRem(100)};
  border-radius: ${pxToRem(4)};
  border-bottom: 2px solid #444;

  @media ${bk.inMobile} {
    margin: ${pxToRem(5)} 0px;
  }
`;

export const ButtonSubmit = styled.button`
  border: 0;
  background-color: transparent;
  color: #000;
  align-self: flex-end;
  font-size: 20px;
  margin-top: ${pxToRem(20)};
  font-weight: bold;
  cursor: pointer;
`;
