export default {
  // header
  about: 'About',
  technology: 'Technology',
  solutions: 'Solutions',
  InTheNews: 'In the news',
  portalUs: 'Portal Access',
  contactUs: 'Contact us',

  // banner
  techHealthOne: 'Technologies',
  techHealthTwo: 'for health',
  descOne: 'Bringing the',
  descTwo: 'future to you',
  submitBanner: 'Submit a request',

  // technologies
  kfar: 'Tel Aviv, Israel',
  kfarDescOne: 'A multinational based',
  kfarDescTwo: 'in Israel and Brazil with offices in Dubai,',
  kfarDescThree: 'USA and Portugal.',
  transTech: 'Transceptar Technologies',
  transTechDescOne:
    'A company focused in genetics and health technology, using dozens of scientific and solid databases in bioinformatics expertise since 2010',
  transTechDescTwo:
    'Transceptar develops advanced artificial intelligence systems with applications in various healthcare sectors. Our technologies are developed using proprietary tools that use the most advanced technological resources of today, developed in our own institution, which allows us to make our operations more flexible globally. We are present in several countries, with our unique technologies and derivative products.',

  // Results
  polyTitle: 'million',
  polyDesc: 'Polymorphisms and mutations analyzed',
  polyDescTwo: 'Scientific publications monitored in real time',
  polyDescThree: 'Accuracy in results with our AI-based system',
  polyDescFinish:
    'Transceptar analyses and processes more than 40 million genetic markers from each individual.',

  // Tech health
  techHealthTitle: 'Health technology',
  techHealthDescOne:
    "Our algorithm, allows us to get precise information based on each individual's DNA, giving the possibility of improving the quality of life along with longevity",
  techHealthDescTwo:
    'We are able to reach 23,000 genes, over 80,000,000 Polymorphisms achieving a very precise result with the greatest amount of genetic predisposition traits of each individual. Our test is all over the world with more than 4,000 healthcare professionals prescribing our DNA panels.',

  // Beneficies
  benDescOne:
    'High precision algorithms for genetics and health developed in Israel',
  benDescTwo: 'Sequencing Strand Inversion Algorithm with ethnicity percentage',
  benDescThree: 'Healthcare case studies provided by health professionals',
  benDescFinish:
    'A.I for Monitoring and updating the database. It absorbs and organizes monthly + 2.5 million scientific publications worldwide.',

  //  Solutions
  solutionsTitle: 'Premium Solutions',
  solutionsDescOne:
    ' Using a proprietary artificial intelligence engine, our genetics platform explores over 3 billion base pairs of each person and delivers a personalized panel',
  solutionsDescTwo:
    ' Effective prevention actions can only be taken when an accurate forecasting method provides accurate data. To achieve these results, Transceptar Analysis is the most powerful genetic platform in the predictive genetics market for healthcare professionals.',
  solutionsFinish:
    'Get access to customized dashboards according to every need with Transceptar Panels.',

  // in the news

  newsOneDate: '26 March 2020',
  newsOneDesc:
    'Startup develops test that identifies genetic vulnerability to Covid-19',

  newsTwoDate: '15 July 2020',
  newsTwoDesc:
    'Startup will work with the University of Washington to help combat covid-19',

  newsThreeDate: '29 January 2021',
  newsThreeDesc:
    'New unicorns? Meet 16 startups from Paraná to keep an eye on 2021',

  copyText: 'Copyright 2022 Transceptar Technologies S/A. All Rights Reserved.',
  sendWithSuccess: 'Send with Success!',

  // Form
  name: 'NAME*',
  lastName: 'LAST NAME*',
  phone: 'PHONE',
  country: 'COUNTRY',
  email: 'EMAIL*',
  company: 'COMPANY',
  message: 'MESSAGE*',
  submit: 'Submit',
};
