export default {
  about: 'Sobre',
  technology: 'Tecnologia',
  solutions: 'Soluções',
  InTheNews: 'Na Mídia',
  portalUs: 'Acesso ao Portal',
  contactUs: 'Entre em contato',

  // banner
  techHealthOne: 'Tecnologias ',
  techHealthTwo: 'para a saúde',
  descOne: 'Trazendo o ',
  descTwo: 'futuro até você',
  submitBanner: 'Entre em contato',

  // technologies
  kfar: 'Tel Aviv, Israel',
  kfarDescOne: 'Multinacional sediada em Israel ',
  kfarDescTwo: 'e no Brasil com escritórios em Dubai,',
  kfarDescThree: ' Portugal e EUA.',

  transTech: 'Transceptar Technologies',
  transTechDescOne:
    'Uma empresa de tecnologia especialista em bioinformática, focada em genética e saúde desde 2010 utilizando sólidas bases de dados científicos avançados',
  transTechDescTwo:
    'A Transceptar desenvolve sistemas avançados de inteligência artificial com aplicações em diversos setores da saúde. Nossas tecnologias são desenvolvidas por meio de ferramentas próprias que utilizam os recursos tecnológicos mais avançados da atualidade, desenvolvidos em nossa própria instituição, o que nos permite flexibilizar nossas operações globalmente. Estamos presentes em diversos países, com nossas tecnologias exclusivas e produtos derivados.',

  // Results
  polyTitle: 'milhões',
  polyDesc: 'Polimorfismos e mutações analisadas',
  polyDescTwo: 'Publicações científicas monitoradas em tempo real',
  polyDescThree: 'Precisão com nosso sistema baseado em IA',
  polyDescFinish:
    'A Transceptar analisa e processa mais de 40 milhões de marcadores genéticos em cada pessoa',

  // Tech health
  techHealthTitle: 'Tecnologias em saúde',
  techHealthDescOne:
    'Nosso algoritmo permite obter informações precisas com base no DNA, possibilitando melhorar a qualidade de vida, juntamente com a longevidade de cada paciente',
  techHealthDescTwo:
    'Analisamos 23.000 genes e mais de 80.000.000 de polimorfismos, alcançando um resultado preciso com maior quantidade de traços de predisposição genética de cada pessoa. Já são mais de 4.000 profissionais de saúde em todo o mundo prescrevendo com nossos painéis de mapeamento genético.',

  // Beneficies
  benDescOne:
    'Algoritmos de alta precisão para genética e saúde desenvolvidos em Israel',
  benDescTwo: 'Sequenciamento de inversão de fita com porcentagem étnica',
  benDescThree: 'Estudos de Casos reais fornecidos por profissionais de saúde',
  benDescFinish:
    'IA para monitoramento e atualização do banco de dados. Absorvendo e organizando mensalmente + 2,5 milhões de publicações científicas em todo o mundo.',

  //  Solutions
  solutionsTitle: 'Soluções únicas',
  solutionsDescOne:
    'Usando um mecanismo de inteligência artificial proprietário, nossa plataforma de genética explora mais de 3 bilhões de pares de bases de cada pessoa e oferece um painel personalizado',
  solutionsDescTwo:
    'Ações de prevenção eficazes só podem ser tomadas quando um método de previsão fornece dados precisos. Para alcançar esses resultados, a Transceptar é a plataforma de análise mais poderosa do mercado de genética preditiva para profissionais de saúde.',
  solutionsFinish:
    'Tenha acesso a painéis personalizados para cada necessidade dos seus pacientes com a Trasceptar.',

  // in the news

  newsOneDate: '26 de Março de 2020',
  newsOneDesc:
    'Startup desenvolve teste que identifica vulnerabilidade genética à Covid-19',

  newsTwoDate: '15 de Julho de 2020',
  newsTwoDesc:
    'Startup vai trabalhar com a Universidade de Washington para ajudar no combate à covid-19',

  newsThreeDate: '29 de Janeiro de 2021',
  newsThreeDesc:
    'Novos unicórnios? Conheça 16 startups do Paraná para ficar de olho em 2021',

  copyText:
    'Copyright 2022 Transceptar Technologies S/A. Todos os direitos reservados.',
  sendWithSuccess: 'Enviado com Sucesso!',

  // Form
  name: 'NOME*',
  lastName: 'SOBRENOME*',
  phone: 'CONTATO',
  country: 'PAÍS',
  email: 'EMAIL*',
  company: 'COMPANHIA',
  message: 'MENSAGEM*',
  submit: 'Enviar',
};
